/*
2021-07-05 SVEN Kommentarbereich für Versionierung, Kommentare
*/

import React from 'react';
import PulseLoader from "react-spinners/PulseLoader";
import './LoadingIndicator.css';

// Ladeanimation

const LoadingIndicator = (props) => {   

    return (
        <PulseLoader/> 
    )
    

};

export default LoadingIndicator;
