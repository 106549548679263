/*
2021-07-05  Kommentarbereich für Versionierung, Bereinigung (Import SimpleTooltip und PacmanLoader raus und auskommentierte, ungenutzte Codebausteine), Kommentare zu Funktionen
            this.setState({loading: false}) Zeile 175, Zeile 186 + if-Abfrage Zeile 222 vor push.loadingindicator --> alle 3 Möglichkeiten der API Abfrage beenden Ladeanimation
2021-07-06  neue example_api_output von neuem Skript Hannes eingefügt  
2021-07-07  pwtogglehandler um sichtbarkeit des passworts bei eingabe zu regulieren --> toggle zwischen type = text und = password
            login_submithandler in zwei funktionen aufgetrennt mit login_changehandler für PW fehlerhaft Meldung
2021-07-08  Barcodescanner integriert    --> noch nicht funktional
            leere Eingabe bei MAC Suchfeld führt trotzdem zu Suche --> lässt sich durch Pattern für das Suchfeld nicht abfangen
2021-07-09  leere bei MAC Suchfeld löst nun mac_error_message aus --> bisher Singlefield mit Fehlermeldung
            andere Bibliothek für Barcodescanner
            Barcodescanner direkt in app.js wegen State --> barcodescanner.js raus aus Komponenten, Barcode_changemachandler raus
2021-07-12  Barcodescanner responsive
2021-07-13  leere Eingabe MAC-Adresse wird durch Inputfield verhindert
2021-07-14  submithandler in zwei Funktionen getrennt je einmal eventbasiert und ohne event, eventless sorgt für automatischen submit bei erfolgreichem barcodescan
2021-07-19  per react-app-rewired und customize-cra vollen Zugriff auf Configfiles erhalten
            react-native Module trotzdem nicht lauffähig --> Barcodemask in CSS selber nachbauen
2021-07-20  Fadenkreuz über Barcodescan eingefügt --> muss noch gestyled werden, verschiebt sich
            MAC-Error-Message erscheint bei Suche nach unbekannter MAC-Adresse (schaut auf status.status ("GW nicht in B.One gefunden")
            visibility der Abfrage beginnt eingeklappt --> TODO Montageplatz und Onlinestatus prominent platzieren  
2021-07-21  Montageplatz und Onlinestatus stehen prominent über den Überschriften und sind dauerhaft sichtbar      
2021-07-22  Montageplatz und Onlinestatus erzeugen Klasse und übergeben diese an SingleFieldAPI je nach gut oder schlecht
            Barcodemask style und responsive + Anleitungstext
2021-07-23  Barcodemask "div in div" Fadenkreuz immer mittig in Video Scrollproblematik beseitigt      
2021-07-28  Login mit Benutzer und Passwort eingebaut (login_submithandler, storecollector, componentdidmount), sessionStorage in Verwendung (leert sich bei tab- und windowclose)
2021-07-29  state.password sinniger benannt --> state.login_ok, code bereinigen
            Fehlermeldungen aufhübschen rote <p> statt Singlefields, loginscreen kein riesen margin zwischen fehlermeldung und eingabefeldern
            logout button inkl styling
2021-08-02  code bereinigen (import singlefield aus app raus), fehlermeldungen styling angepasst, multifields mit margin-bottom desktopansicht um bereich fürs aufklappen zu reservieren, vorbereitung impressum 
2021-08-03  navbar oben mit link zu impressum und logout (cleart sessionStorage mit logintoken) + datenschutzerklärung , erster versuch manipulation klasse multifield bei öffnen des tooltipps TODO funktioniert noch nicht richtig
2021-08-04  divschieber übergabe von simpletooltip zu multifield weiterhin nicht funktional, loginscreen optisch angepasst, fehlermeldung falscher login klarer gemacht
2021-08-12  neues Hannesskript vom 06.08. einbauen --> APIResponse, Marcus Anweisungen einbauen
2021-08-13  Marcus Anweisungen einbauen
2021-08-14  Marcus Anweisungen einbauen, APIResponse.js: firmware_data erzeugt firmwareversion und aktualität in einem Feld, außerdem wird bei unbekannter FIrmware keine Aktualität erzeugt
2021-08-18  htmls hochgeladen und relative Verknüpfungen passend angelegt
2021-08-19  erklärtooltips mit react-tooltip, Komponente erzeugt --> Import in SngleFieldAPI & MultiField dort Erzeugung von Button für Erklärtooltip
2021-08-23  Tuttooltips BUG - nach ausklappen und wieder einklappen aller Felder Tooltips der oberen drei Felder nicht mehr aufrufbar, liegt wohl an Sichtbarkeit der SingleFields --> noch nicht gefixt
2021-08-24  Tooltiptexte nochmal verbessert, Zeitstempel letzte Verbindung  kommt als Unixzeitstempel und wird umgerechnet in Localetime
2021-08-25  simpletooltip angepasst, erzeugt jetzt feld im MultiField anstatt tooltip, besser lesbar + verschiebt darunter liegendes feld, damit nichts verdeckt wird
            changemachandler entfernt automatisch Leerzeichen und andere Whitespacecharacters die in Inputfield eingetragen/reinkopiert werden
2021-11-04  anpassung navbar doku zum aufklappen, impressum und datenschutz wird auch nicht eingeloggt angezeigt, anpassungen an hannes skript einbauen, tuttooltip an technologien
2021-11-05  anpassung neues skript, tooltips, anzeigewerte
2021-11-05  online seit --> letzte verbindung (war missverständlich), logout löscht visibility der letzen abfrage (kommt zum tragen, wenn direkt wieder eingeloggt wird), css anpassungen verschiedene bildschirmgrößen, code aufräumen
*/

import React, { Component, useState, useEffect } from "react";
import "./App.css";
import InputField from "./Components/InputField";
import APIResponse from "./Components/APIResponse";
import LoadingIndicator from "./Components/LoadingIndicator";
import Loginscreen from "./Components/Loginscreen";
import "./Components/SingleFieldAPI.css";
import BarcodeScannerComponent from "react-qr-barcode-scanner";



class App extends Component {
  state = {
    macaddress: "",
    example_api_output: {
      "mac_address": "04B648108FC6",
	"mobile": {
		"provider_data": {
			"o2 - de": {
				"gsm": {
					"rssi": -97
				},
				"umts": {
					"rscp": "-",
					"ecio": "-"
				},
				"lte": {
					"rsrq": "-",
					"rsrp": "-"
				},
				"quality": "Schlecht"
			},
			"Telekom.de": {
				"gsm": {
					"rssi": "-"
				},
				"umts": {
					"rscp": "-",
					"ecio": "-"
				},
				"lte": {
					"rsrq": -16,
					"rsrp": -124
				},
				"quality": "Schlecht"
			}
		},
		"act_operator": "o2 - de",
		"act_access_technology": "GSM"
	},
	"status": {
		"firmware_data": {
			"firmware_name": "IDUv2_V1.1.7",
			"firmware_status": "Aktuell"
		},
		"connection_data": {
			"reconnect_count": 254,
			"online_status": "Online",
			"reconnect_quality": "Schlecht",
			"on_off_value": 14.56,
			"conn_quality": "Schlechte Verbindung zum Backend",
			"last_connect": 1636978066
		},
		"status": "GW in B.One gefunden",
		"mobile_quality_rating": "Schlechte Verbindung zum Mobilfunknetz",
		"mobile_quality_rating_reason": "Mobilfunk schlecht",
		"instruction": "Montageort muss verändert werden"
	},
  },
    api_output: {},
    visibility: false,
    use_api: true,
    show_error_message: false,
    show_mac_error_message: false,
    show_error_message_token: false,
    show_error_message_pw: false,
    visibility_detail_provider: false,
    visibility_provider_information: false,
    visibility_status: false,
    loading: false,
    login_ok: false,
    password_input: "",
    pw_visibility: false,
    visibility_barcode_scanner: false,
    username_input: "",
  };

  // startet storeCollector sobald Seite lädt
  componentDidMount() {
    this.storeCollector();
  }

  // storeCollector packt logintoken in den sessionstorage (löscht sich bei Tab- oder Fensterclose)
  storeCollector() {
    let store = JSON.parse(sessionStorage.getItem("login"));
    if (store && store.token) {
      this.setState({ login_ok: true, store: store });
    }
  }

  //toggle für pw visbility
  pwtogglehandler = () => {
    const toggle_pw = this.state.pw_visibility ? false : true;
    this.setState({
      pw_visibility: toggle_pw,
    });
  };

  // Handler um eingegebene MAC-Adressen aus Inputfield MAC in State zu überführen, schneidet außerdem Leerzeichen,  Doppelpunkte und Bindestriche aus Eingabe
  changemachandler = (event) => {
    let input_mac = event.target.value;
    let new_input_mac = input_mac.split(":").join("").split("-").join("").replace(/\s+/g, '');
    // let new_input_mac = input_mac.split(":").join("").split("-").join("").trim();
    this.setState({ macaddress: new_input_mac });
  };

  // Toggle um Status ein- und auszuklappen
  clickhandler_status = () => {
    const toggle_status = this.state.visibility_status 
    ? false 
    : true;
    this.setState({
      visibility_status: toggle_status,
    });
  };
  // Toggle um Provider Informationen ein- und auszuklappen
  clickhandler_provider_information = () => {
    const toggle_provider_information = this.state
      .visibility_provider_information
      ? false
      : true;

    this.setState({
      visibility_provider_information: toggle_provider_information,
    });
  };
  // Toggle um Providerdetails ein- und auszuklappen
  clickhandler_detail_provider = () => {
    const toggle_detail_provider = this.state.visibility_detail_provider
      ? false
      : true;
    this.setState({
      visibility_detail_provider: toggle_detail_provider,
    });
  };

  // clickhandler für logoutbutton --> löscht sessionStorage mit token, setzt login_ok auf false, löscht username_ und pw_input
  clickhandler_logout = () => {
    this.setState({login_ok: false});
    this.setState({password_input: ""})
    this.setState({username_input: ""})
    this.setState({visibility: false})
    this.setState({mac_address: ""})
    sessionStorage.clear();
  }


  // Handler, der Benutzername und Passwort aus Loginscreen an API gibt
  // bei Fehlercode 401 & 403 wird error_message_pw getriggert
  // gibt username und password an jeweils eigene submithandler
  // ACHTUNG login in localhost nicht mehr möglich, da ersetzt
  login_submithandler = (event) => {
    event.preventDefault();
    this.setState({ show_error_message_token: false });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      username: this.state.username_input,
      password: this.state.password_input,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`https://tool.zenner-connect.com/mzc-api/login`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        result = JSON.parse(result);
        if (result.access_token) {
          sessionStorage.setItem(
            "login",
            JSON.stringify({
              login: true,
              token: result.access_token,
            })
          );
          this.storeCollector();
          this.setState({ show_error_message_pw: false });
        } else if (result.status_code === 401 || result.status_code === 403) {
          this.setState({ show_error_message_pw: true });
        }
      })
      .catch((error) => console.log("error", error));
  };
  // handler für übergabe username
  login_submithandler_username = (event) => {
    this.setState({ username_input: event.target.value });
  };
  // handler für übergabe password
  login_submithandler_password = (event) => {
    this.setState({ password_input: event.target.value });
  };

  // Clickhandler für Barcodescan Sichtbarkeit
  clickhandler_barcode = () => {
    const toggle_barcode = this.state.visibility_barcode_scanner ? false : true;
    this.setState({
      visibility_barcode_scanner: toggle_barcode,
    });
    this.setState({ show_mac_error_message: false });
    this.setState({ show_error_message: false });
    this.setState({ visibility: false });
  };

  /* Handler für API Abfrage mit echter Abfrage oder im else Beispieldaten aus State
  Ablauf:
   entfernt bisherige Darstellung (Fehler oder Daten alte Abfrage) und erzeugt Ladeanimation
   API Abfrage mit MAC-Adresse aus State 2 Möglichkeiten
   erfolgreich: Ladeanimation weg und abgefragte Daten werden dargestellt
   nicht erfolgreich: Fehlermeldung
   MAC-Error: api_output.status.status = "GW nicht in B.One gefunden"
   bei submit wird Aktualität des Tokens geprüft --> ausgelaufen liefert 401 wird im error gecatcht error.name"TokenAusgelaufen"
  */
  eventless_submithandler = () => {
    this.setState({ show_error_message: false });
    this.setState({ show_mac_error_message: false });
    this.setState({ visibility: false });
    this.setState({ loading: true });
    this.setState({ visibility_barcode_scanner: false });

    if (this.state.use_api === true) {
      fetch(`https://tool.zenner-connect.com/mzc-api/requestmac`, {
        method: "POST",
        body: JSON.stringify({ mac: this.state.macaddress }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.state.store.token,
        },
      })
        .then((response) => {
          console.log(response);
          if (response.status === 401) {
            console.log("Fehler token");
            const error = new Error("token_ausgelaufen");
            error.name = "TokenAusgelaufen";
            throw error;
          }
          return response.json();
        })
        .then((mac_information) => {
          this.setState({ api_output: mac_information });
          this.setState({ visibility: true });
          this.setState({ loading: false });
          if (
            this.state.api_output.status.status === "GW nicht in B.One gefunden" || this.state.example_api_output.status.status === "Ungueltige Mac-Adresse eingegeben"
          ) {
            this.setState({ visibility: false });
            this.setState({ show_mac_error_message: true });
          }
        })
        .catch((error) => {
          if (error.name === 'TokenAusgelaufen') {
            console.log("error", error);
            this.setState({ login_ok: false });
            this.setState({ show_error_message_token: true });
            this.setState({ loading: false });  
            sessionStorage.clear();          
          }
          else {
            console.log("error", error);
            this.setState({ show_error_message: true });
            this.setState({ loading: false });
          }
          
          
        });
    } else {
      const example_api_output = {
        ...this.state.example_api_output,
      };
      this.setState({ api_output: example_api_output });
      this.setState({ visibility: true });
      this.setState({ loading: false });
      if (
        this.state.example_api_output.status.status ===
        "GW nicht in B.One gefunden" || this.state.example_api_output.status.status === "Ungueltige Mac-Adresse eingegeben"
      ) {
        this.setState({ visibility: false });
        this.setState({ show_mac_error_message: true });
      }
    }
  };

  // submithandler mit event für Inputfield in Verwendung
  event_submithandler = (event) => {
    event.preventDefault();
    this.eventless_submithandler();
  };

  render() {
    // aufruf --> Ausgabe von APIResponse, error_message --> Fehlermeldung, mac_error_message --> Fehler bei Eingabe MAC-Adresse 
    // loading --> Ladeanimation, error_message_password --> fehlermeldung anmeldung falsch, barcodescanner, error_message_token --> Fehlermeldung bei Token ausgelaufen
    let aufruf = [];
    let error_message = [];
    let mac_error_message = [];
    let loading = [];
    let error_message_password = [];
    let barcodescanner = [];
    let error_message_token = [];

    // Weiterabe an APIResponse, wenn alles in Ordnung
    if (this.state.visibility === true) {
      aufruf.push(
        <APIResponse
          apioutput={this.state.api_output}
          key={"APIResponse"}
          visibility_detail_provider={this.state.visibility_detail_provider}
          clickhandler_detail_provider={this.clickhandler_detail_provider}
          visibility_provider_information={this.state.visibility_provider_information}
          clickhandler_provider_information={this.clickhandler_provider_information}
          visibility_status={this.state.visibility_status}
          clickhandler_status={this.clickhandler_status}
        />
      );
    }

    // Weitergabe an Singlefield mit Fehlermeldung
    if (this.state.show_error_message === true) {
      error_message.push(
       <p class="wrong_password_text">Bitte Verbindung prüfen!</p>
      );
    }

    // Fehlermeldung bei abgelaufenem Token
    if (this.state.show_error_message_token === true) {
      error_message_token.push(
        <p class="wrong_password_text">
          Token abgelaufen!<br />Bitte neu anmelden
        </p>
      );
    }

    // Weitergabe an Singlefield mit Fehlermeldung MAC
    if (this.state.show_mac_error_message === true) {
      mac_error_message.push(
        <p class="wrong_password_text">MAC-Adresse nicht vorhanden!</p>
      );
    }

    // Weitergabe Ladeanimation, wenn Loading auf True
    if (this.state.loading === true) {
      loading.push(<LoadingIndicator />);
    }

    // Weitergabe Singlefield sobald PW falsch
    if (this.state.show_error_message_pw === true) {
      error_message_password.push(
        <p class="wrong_password_text">Logindaten fehlerhaft!</p>
      );
    }

    /* Anzeige Barcodescanner, wenn visibility barcodescanner = true
      barcodescanner wird mit Barcodescannerkomponente befüllt bei erfolgreichem Scannen eines Codes wird dieser in den state an stelle der macaddress eingetragen
      bei erfolgreichem Scan wird direkt API abfrage durchgeführt --> eventless_submithandler
      divs zur Platzierung von Video und Barcodemask in Browser und auf Mobilgeräten
    */
    if (this.state.visibility_barcode_scanner === true) {
      barcodescanner.push(
        <div className="div_aussen">
           <BarcodeScannerComponent       
            delay={500}
            // torch={true}
            onUpdate={(error, result) => {
              if (result) {
                let result_scan = (result.text)
                result_scan = result_scan.substr(0, 12)
                this.setState({macaddress: result_scan});
                this.setState({visibility_barcode_scanner: false});
                this.eventless_submithandler();
              }
            }
           }
          />
          <div className="barcodemask"></div>
          <p className="qr_text">
            Bitte den QR-Code innerhalb des Quadrats platzieren
          </p>
        </div>
      );
    }

    // wenn Password richtig eingegeben wurde wird Seite aufgebaut, else Loginscreen mit login_submithandler wird angezeigt
    if (this.state.login_ok === true) {
      return ( // navbar in Kopfzeile, voller inhalt wenn eingeloggt       
        <div className="AppStyle"> 
          <ul>  
            <li><a className="logout" onClick={this.clickhandler_logout}>Logout</a></li>
            <li className="li_impressum_datenschutz"><a href='html/impressum.html' className="impressum" >Impressum</a></li>
            <li className="li_impressum_datenschutz"><a href='html/datenschutzerklaerung.html' className="datenschutz" >Datenschutz</a></li>

            <div class="dropdown">
                <li class="dropbtn">Dokumentationen</li>
                <div class="dropdown-content">
                  <a href="html/dokumentation-allgemeine-info.html">allgemeine Info</a>
                  <a href="html/dokumentation-begehung.html">Begehungsdoku</a>
                  <a href="html/dokumentation-montage.html">Montagedoku</a>
                </div>
            </div> 
          </ul>
          
          <InputField
            changed={(event) => this.changemachandler(event)}
            value={this.state.macaddress}
            submit={(event) => this.event_submithandler(event)}
          />
          <button onClick={this.clickhandler_barcode}>
            MAC-Adresse aus QR-Code scannen
          </button>
          
          {barcodescanner}
          {aufruf}
          {error_message}
          {mac_error_message}
          {loading}
        </div>
      );
    } 
    else { // nicht eingeloggt
      return ( // navbar in Kopfzeile, enthält nur impressum und datenschutz wenn nicht eingeloggt
      <div className="AppStyle">
          <div> 
            <ul>  
              <li className="li_impressum_datenschutz"><a href='html/impressum.html' className="impressum" >Impressum</a></li>
              <li className="li_impressum_datenschutz"><a href='html/datenschutzerklaerung.html' className="datenschutz" >Datenschutz</a></li>
            </ul>
          </div>
        <div
          className={
            this.state.show_error_message_pw
              ? "login_password_wrong"
              : "login_password_before"
          }
        >
          {error_message_password}
          {error_message_token}
          <Loginscreen
            changed={(event) => this.login_submithandler_password(event)}
            username_changed={(event) =>
              this.login_submithandler_username(event)
            }
            value={this.state.password_input}
            type={this.state.pw_visibility}
            username_value={this.state.username_input}
            submit={(event) => this.login_submithandler(event)}
            pw_toggler={(event) => this.pwtogglehandler(event)}
          />
        </div>
      </div>
      );
    }
  }
}

export default App;
