/*
2021-08-19 Kommentarbereich für Versionierung, Kommentare
*/

import React from 'react';
import ReactTooltip from 'react-tooltip';
import './TutTooltip.css'


// Tooltip für Erklärtexte

const TutTooltip = (props) => {   

    return (
        <ReactTooltip
         type= "light" 
         effect= "solid" 
         globalEventOff='click' 
         place="top"
         multiline="true"  
         className="tuttooltip"
         arrowColor="grey"
         />
    )
    

};

export default TutTooltip;
