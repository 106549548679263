/*
2021-07-05 SVEN Kommentarbereich für Versionierung, Kommentare

2021-07-07 SVEN Passwortsichtbarkeit togglebar 
*/

import React from 'react';
import './Loginscreen.css'

// Eingabefeld für Passwort TODO echten Login mit Benutzer + PW
const Loginscreen = (props) => {
    return (
        <form onSubmit={props.submit}>
            <p className="loginheader">Login</p>
            <input  
                id="login_username"
                name="Login-Username"
                placeholder= "Username"
                type="text"
                value={props.username_value}
                onChange={props.username_changed}
            />
            <input  
                id="login_password"
                name="Login-Password"
                placeholder= "Passwort"
                type={props.type ? "text" : "password"}
                value={props.value}
                onChange={props.changed}
            />
            <br></br>
        <div id="checkbox_pw">
        <input type="checkbox" name="checkbox_pw" onClick={props.pw_toggler}/>
        <label for="checkbox_pw">Passwort anzeigen</label>
        </div>
        <br></br>
        <button onClick={props.submit}>Anmelden</button>
        </form>
    )           
};
export default Loginscreen;