/*
2021-07-05 SVEN Kommentarbereich für Versionierung
    --> Mapping Überschriften im Tooltip, Einheiten
2021-08-25  simpletooltip angepasst, erzeugt jetzt feld im MultiField anstatt tooltip, besser lesbar + verschiebt darunter liegendes feld, damit nichts verdeckt wird
            + Bereinigung Codeüberreste "divschieber"
*/ 

import React from "react";
import Popup from "reactjs-popup";
import './MultiField.css';
import './SimpleTooltip.css'

// SimpleTooltip in Verwendung bei Multifield Werte bei Qualität der Provider
const SimpleTooltip = (props) => {

    // Mapper für schöne Überschriften
    const mapper = {
        "rssi": "RSSI",
        "rsrq": "RSRQ",
        "rsrp": "RSRP",
        "rscp": "RSCP",
        "ecio": "EC/IO"
    }

    let popup_active = []
    let signal_information_to_show = []
    const access_technology = props.signal_information

    // Befüllen des Tooltipps, Mapper schaut auf key_access_technology
    const keys_access_technology = Object.keys(access_technology)
    for (const key_access_technology of keys_access_technology) {
        if (key_access_technology === 'rsrq' || key_access_technology === 'ecio'){
            signal_information_to_show.push(<p>{mapper[key_access_technology]}: {access_technology[key_access_technology]} dB</p>)
        }
        if (key_access_technology !=='rsrq' && key_access_technology !== 'ecio'){
        signal_information_to_show.push(<p>{mapper[key_access_technology]}: {access_technology[key_access_technology]} dBm</p>)
        }
    }

    if (props.state === 'active') {
        
        popup_active.push(<Popup 
            trigger={open => (
                
                <details>
                <summary className={props.class}>{props.name} {props.tooltip}</summary>
                    <div class="show_signal_information"> 
                        {signal_information_to_show}                  
                    </div>  
                </details>
                
                )}
                position="bottom down"
                closeOnDocumentClick
                >
            

        </Popup>)

    }
    else {
        popup_active.push(<p className={props.class}>{props.name} {props.tooltip}</p>)
    }


    return (
        <React.Fragment>  
            {popup_active}
        </React.Fragment>
    )
}
export default SimpleTooltip;