/*
2021-07-05 SVEN Kommentarbereich für Versionierung, Kommentare
2021-07-06 SVEN Hannes hat sein Skript angepasst und der Status hat neue Informationen geliefert --> Anpassung der Abfrage und Weitergabe an SingleFieldAPI Zeile 15-44 + Return Zeile 71-73
2021-07-21 SVEN Montageplatz und Onlinestatus stehen prominent über den Überschriften und sind dauerhaft sichtbar 
2021-07-22 SVEN Montageplatz und Onlinestatus erzeugen Klasse und übergeben diese an SingleFieldAPI je nach gut oder schlecht
2021-08-12      montageplatz 4 mögliche ergebnisse --> geeignet, nicht geeignet Ausgabe in grün oder rot; bedingt geeignet verbindungsquote, bedingt geeignet reconnects in orange 
                Anpassungen Anweisung Marcus
2021-08-13      Firmwareausgabe zusammenführen Firmwareversion und Status in einem Feld
2021-08-16      firmware_data erzeugt firmwareversion und aktualität in einem Feld, außerdem wird bei unbekannter FIrmware keine Aktualität erzeugt
*/

import React from 'react';
import SingleFieldAPI from './SingleFieldAPI';
import MultiField from './MultiField';


// APIResponse erzeugt auf Basis der Daten aus der Middleware die Anzeigeboxen und gibt diese an die App zurück
const APIResponse = (props) => {
    // Übernahme apioutput.status aus App.js, wenn visibility_status = true --> Push der Singlefields für alle Keys 
    // Regelung der Sichtbarkeit über Toggle --> clickhandler_status     
    // status.firmware_data für Weitergabe an SingleFieldAPI
    // firmware_data erzeugt firmwareversion und aktualität in einem Feld, außerdem wird bei unbekannter Firmware keine Aktualität erzeugt
    let api_status_firmware_data = props.apioutput.status.firmware_data;
    let aufruf_status_firmware_data = []
    let header_firmware = "Firmware"
    let value_firmware_status = ""
    let value_firmware_version = ""
    let value_firmware_ausgabe = ""
    const keys_firmware_data = Object.keys(api_status_firmware_data) 
       if (props.visibility_status === true) {
            for (const key_firmware_data of keys_firmware_data) {
                if (key_firmware_data === "firmware_name") {value_firmware_status = api_status_firmware_data[key_firmware_data]}
                if (key_firmware_data === "firmware_status") {value_firmware_version = api_status_firmware_data[key_firmware_data]}
                if (value_firmware_status === "Unbekannt"){
                    value_firmware_ausgabe = value_firmware_status
                }
                if (value_firmware_status !== "Unbekannt"){
                    value_firmware_ausgabe = value_firmware_status + " (" + value_firmware_version + ")"
                }                
            }
            aufruf_status_firmware_data.push(<SingleFieldAPI header={header_firmware} value={value_firmware_ausgabe} key="key_firmware_data"/>)
       } 

    let api_status_connection_data = props.apioutput.status.connection_data;   
    const keys_connection_data = Object.keys(api_status_connection_data) 

    //onlinestatus für weitergabe an SingleFieldAPI --> prominent platziert + dauerhaft sichtbar, gut="online" schlecht="offline"
    let aufruf_status_onlinestatus = [];
    let onlineclass = [];
            for (const key_connection_data of keys_connection_data) {
                if (key_connection_data === "online_status") 
                    {       
                        if (api_status_connection_data[key_connection_data] === "online" || api_status_connection_data[key_connection_data] === "Online"){
                            onlineclass = "green"
                        }
                        else if (api_status_connection_data[key_connection_data] === "offline" || api_status_connection_data[key_connection_data] === "Offline"){
                            onlineclass = "red"
                        }

                        // if (key_connection_data === 'online_status')
                        aufruf_status_onlinestatus.push(<SingleFieldAPI klasse={onlineclass} header={key_connection_data} value={api_status_connection_data[key_connection_data]} key={key_connection_data} />)
                    }
                }
                
    // Verbindungsqualität für Weitergabe an SingleFieldAPI --> prominent platziert + dauerhaft sichtbar, gut-"grün/instabil-"orange"/schlecht-"rot"           
    let aufruf_status_connquality = [];
    let connqualityclass = [];
    let value_connquality = "";
    let weglassen= false;
            for (const key_connection_data of keys_connection_data) {
                if (key_connection_data === "conn_quality") {
                        //gut
                        if (api_status_connection_data[key_connection_data] === "Gute Verbindung zum Backend"){
                            connqualityclass = "green"
                            value_connquality = "Gut"
                        }
                        //instabil
                        else if (api_status_connection_data[key_connection_data] === "Instabile Verbindung zum Backend"){
                            connqualityclass = "orange"
                            value_connquality = "Instabil"
                        }
                        //schlecht
                        else if (api_status_connection_data[key_connection_data] === "Schlechte Verbindung zum Backend"){
                            connqualityclass = "red"
                            value_connquality = "Schlecht"
                        }
                        else if (api_status_connection_data[key_connection_data] === "Keine Verbindung zum Backend"){
                            connqualityclass = "red"
                            value_connquality = "Schlecht"
                        }
                        //keine bewertung möglich
                        else if (api_status_connection_data[key_connection_data] === "Keine Bewertung der Backendverbindung möglich"){
                            connqualityclass = "grey"
                            value_connquality = ""
                            weglassen=true;
                        }
                            aufruf_status_connquality.push(<SingleFieldAPI klasse={connqualityclass} header={key_connection_data} value={api_status_connection_data[key_connection_data]} key={key_connection_data} />)
                }
                }

    //status.connection data für Weitergabe an SingleFieldAPI !ohne Online_status, ohne conn_quality --> siehe folgecode
    //Verbindungsquote 7d Wert in Prozent
    //reconnects und reconnect quality zusammenführen
    //let api_status_connection_data = props.apioutput.status.connection_data;
    let aufruf_status_connection_data = []
    let header_reconnect = "Reconnects"
    let header_connquality = "on_off_value"
    let header_lastconnect = "test"
    let value_reconnects_7d = ""
    let value_reconnects_quality = ""
    let value_connqualityquote = ""
    let qualityclass = []  
   // const keys_connection_data = Object.keys(api_status_connection_data) 
        if (props.visibility_status === true) {
            for (const key_connection_data of keys_connection_data) {
                if (key_connection_data === 'reconnect_count') {
                    value_reconnects_7d = api_status_connection_data[key_connection_data]
                }              
                if (key_connection_data === 'reconnect_quality'){
                    value_reconnects_quality = api_status_connection_data[key_connection_data]
                    let value_reconnect_ausgabe = value_reconnects_quality + " (" + value_reconnects_7d + " in den letzten 7 Tagen)" 
                    aufruf_status_connection_data.push(<SingleFieldAPI klasse={qualityclass} header={header_reconnect} value={value_reconnect_ausgabe} key={key_connection_data}/>)
                }
                if (key_connection_data === 'on_off_value'){
                    value_connqualityquote = api_status_connection_data[key_connection_data]
                    let value_connqualityquote_ausgabe = ""
                    // if(value_connqualityquote !== "kein_7dv_vorhanden.") {
                    //     value_connqualityquote_ausgabe = value_connqualityquote + "%" + " (" + value_connquality + ")"  
                    // }
                    if(value_connqualityquote === "Keine Werte vorhanden" || value_connqualityquote === "kein_7dv_vorhanden") {
                        value_connqualityquote_ausgabe = value_connqualityquote  
                    }
                    else {
                        value_connqualityquote_ausgabe = value_connqualityquote + "%" + " (" + value_connquality + ")"  
                    }
                    aufruf_status_connection_data.push(<SingleFieldAPI klasse={qualityclass} header={header_connquality} value={value_connqualityquote_ausgabe} key={key_connection_data}/>)
                }
                
                // Titel last_connect ändert sich zu Letzter Verbindungszeitpunkt / Offline seit in Abhängigkeit bon Verbindungsstatus
                if (key_connection_data === "online_status") 
                    {       
                        if (api_status_connection_data[key_connection_data] === "online" || api_status_connection_data[key_connection_data] === "Online"){
                            header_lastconnect = "online_since"
                        }
                        else if (api_status_connection_data[key_connection_data] === "offline" || api_status_connection_data[key_connection_data] === "Offline"){
                            header_lastconnect = "offline_since"
                        }
                    }

                
                // lastconnect wird als Unixzeitstempel empfangen und wird in localetime geändert
                if (key_connection_data === 'last_connect'){                   
                    if (api_status_connection_data[key_connection_data] === "Keine Werte vorhanden") {
                        let value_timestamp = "Keine Werte vorhanden"
                        aufruf_status_connection_data.push(<SingleFieldAPI header={header_lastconnect} value={value_timestamp} key={key_connection_data}/>)
                    }
                    else {                       
                        let value_timestamp_unix =  api_status_connection_data[key_connection_data] *1000
                        let value_timestamp = new Date(value_timestamp_unix)
                        value_timestamp = value_timestamp.toLocaleTimeString('de-DE', { 
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit"
                        })
                        aufruf_status_connection_data.push(<SingleFieldAPI header={header_lastconnect} value={value_timestamp} key={key_connection_data}/>)
                    }
                    
                }
            }
        }

    //aufruf_status enthält nur montageplatz --> prominent platziert + dauerhaft sichtbar gut="Geeignet"--> grün, schlecht="Nicht geeignet"--> rot, bedingt geeignet --> orange
    //bisher hier mounting place rating --> geändert in mobile_quality_rating
    let api_status = props.apioutput.status;
    let aufruf_status = []
    let mobileclass =[]
    const keys_status = Object.keys(api_status) 
   
            for (const key_status of keys_status) {
                if (key_status === "mobile_quality_rating") {
                        if (api_status[key_status] === "Gute Verbindung zum Mobilfunknetz"){
                            mobileclass = "green"
                            aufruf_status.push(<SingleFieldAPI klasse={mobileclass} header={key_status} value={api_status[key_status]} key={key_status}/>)
                        }
                        else if (api_status[key_status] === "Ausreichende Verbindung zum Mobilfunknetz")
                                {
                            mobileclass = "orange"                     
                            aufruf_status.push(<SingleFieldAPI klasse={mobileclass} header={key_status} value={api_status[key_status]} key={key_status}/>)
                        }
                        //else if (api_status[key_status] === "Nicht Geeignet" || api_status[key_status] === "Nicht geeignet" || api_status[key_status] === "Ungeeignet" ){
                        else if (api_status[key_status] === "Schlechte Verbindung zum Mobilfunknetz"){    
                            mobileclass = "red"
                            aufruf_status.push(<SingleFieldAPI klasse={mobileclass} header={key_status} value={api_status[key_status]} key={key_status}/>)
                        }
                        else if (api_status[key_status] === "Keine Informationen zum Mobilfunk"){    
                            mobileclass = "grey"
                            aufruf_status.push(<SingleFieldAPI klasse={mobileclass} header={key_status} value={api_status[key_status]} key={key_status}/>)
                        }                        
                    }
            }    


        // hier wird der mobile_quality_reason verarbeitet und die visibility gesteuert
        // ablauf siehe montagerating bzw. mobile_quality_rating
        // liegt bisher im "expertenmodus" --> wird vielleicht noch angepasst   
        let aufruf_mobile_quality_reason = []
        if (props.visibility_status === true) {     
                for (const key_status of keys_status) {
                    if (key_status === "mobile_quality_rating_reason") {
                            if (api_status[key_status] === "Mobilfunk gut"){
                                mobileclass = "green"
                                aufruf_mobile_quality_reason.push(<SingleFieldAPI klasse={mobileclass} header={key_status} value={api_status[key_status]} key={key_status}/>)
                            }
                            else if (api_status[key_status] === "Mobilfunk ausreichend, viele Verbindungsversuche")
                                    {
                                mobileclass = "orange"                     
                                aufruf_mobile_quality_reason.push(<SingleFieldAPI klasse={mobileclass} header={key_status} value={api_status[key_status]} key={key_status}/>)
                            }
                            //else if (api_status[key_status] === "Nicht Geeignet" || api_status[key_status] === "Nicht geeignet" || api_status[key_status] === "Ungeeignet" ){
                            else if (api_status[key_status] === "Mobilfunk ausreichend, wenig Verbindungsversuche"){    
                                mobileclass = "orange"
                                aufruf_mobile_quality_reason.push(<SingleFieldAPI klasse={mobileclass} header={key_status} value={api_status[key_status]} key={key_status}/>)
                            }
                            else if (api_status[key_status] === "Mobilfunk schlecht, viele Verbindungsversuche"){    
                                mobileclass = "red"
                                aufruf_mobile_quality_reason.push(<SingleFieldAPI klasse={mobileclass} header={key_status} value={api_status[key_status]} key={key_status}/>)
                            }
                            else if (api_status[key_status] === "Mobilfunk schlecht")
                                    {
                                mobileclass = "red"                     
                                aufruf_mobile_quality_reason.push(<SingleFieldAPI klasse={mobileclass} header={key_status} value={api_status[key_status]} key={key_status}/>)
                            }      
                            else if (api_status[key_status] === "Keine Bewertung des Mobilfunks möglich")
                                    {
                                mobileclass = "grey"                     
                                aufruf_mobile_quality_reason.push(<SingleFieldAPI klasse={mobileclass} header={key_status} value={api_status[key_status]} key={key_status}/>)
                            }                     
                        }
                }
            }

            let aufruf_status_instruction = []
                    for (const key_status of keys_status) {
                        if (key_status === "instruction") {
                                if (api_status[key_status] === "Keine Aktion notwendig"){
                                    mobileclass = "green"
                                    aufruf_status_instruction.push(<SingleFieldAPI klasse={mobileclass} header={key_status} value={api_status[key_status]} key={key_status}/>)
                                }
                                else if (api_status[key_status] === "Montageort ggf. überprüfen")
                                        {
                                    mobileclass = "orange"                     
                                    aufruf_status_instruction.push(<SingleFieldAPI klasse={mobileclass} header={key_status} value={api_status[key_status]} key={key_status}/>)
                                }
                                //else if (api_status[key_status] === "Nicht Geeignet" || api_status[key_status] === "Nicht geeignet" || api_status[key_status] === "Ungeeignet" ){
                                else if (api_status[key_status] === "Montageort muss verändert werden"){    
                                    mobileclass = "red"
                                    aufruf_status_instruction.push(<SingleFieldAPI klasse={mobileclass} header={key_status} value={api_status[key_status]} key={key_status}/>)
                                }
                                else if (api_status[key_status] === "Keine Gesamtbewertung möglich"){    
                                    mobileclass = "grey"
                                    aufruf_status_instruction.push(<SingleFieldAPI klasse={mobileclass} header={key_status} value={api_status[key_status]} key={key_status}/>)
                                }          
                            } 
                }

    // siehe Status nur mit Provider Data
    let api_provider_data = props.apioutput.mobile.provider_data;
    let provider_data = []
    const keys_provider_data = Object.keys(api_provider_data)
    let provider_information = []
    
    // // Regelung der Sichtbarkeit über Toggle --> clickhandler_provider_information
     if (props.visibility_provider_information === true) {
       provider_information.push(<SingleFieldAPI header={"act_operator"} value={props.apioutput.mobile.act_operator} key={"act_operator"}/>)
       provider_information.push(<SingleFieldAPI header={"act_access_technology"} value={props.apioutput.mobile.act_access_technology} key={"act_access_technology"}/>)
    }

    // // Regelung der Sichtbarkeit über Toggle --> clickhandler_detail_provider
     if (props.visibility_detail_provider == true) {
        for (const key_provider_data of keys_provider_data) {
            provider_data.push(<MultiField header={key_provider_data} value={api_provider_data[key_provider_data]} key={key_provider_data}/>)
        }
     } 

    // return an App.js 
    // divs für jede Überschrift wird erzeugt, diese reagieren jeweils auf Klick und klappen ein- und aus, + und - an der Übersicht macht jeweils deutlich, dass hier eine Interaktion möglich ist
    return (
        <div>
            <h1>Bewertung Mobilfunkparameter von GW: </h1>
            <h1>{props.apioutput.mac_address}</h1> 
            <p>{aufruf_status_instruction}</p>
            {aufruf_status}
            {aufruf_status_onlinestatus}
            {aufruf_status_connquality}
            

            <div id="ueberschrift_status">
                <h2 class="active-header" onClick={props.clickhandler_status}>Firmware und Server-Verbindungsdetails
                    <div class="active" onClick={props.clickhandler_status} className={props.visibility_status ? "minus" : "plus"}></div>
                </h2>
            </div>
            {aufruf_status_firmware_data}
            {aufruf_status_connection_data}
            {aufruf_mobile_quality_reason}  
           
            

            <div id="ueberschrift_providerinfo">
                <h2 class="active-header" onClick={props.clickhandler_provider_information}>Aktuelle Mobilfunk-Providerinformationen 
                    <div class="active" onClick={props.clickhandler_provider_information} className={props.visibility_provider_information ? "minus" : "plus"}></div> 
                </h2>
            </div>
            {provider_information}
            <div id="ueberschrift_detailinfos">
                <h2 class="active-header" onClick={props.clickhandler_detail_provider}>Detailinfos zuletzt genutzte Provider 
                    <div class="active" onClick={props.clickhandler_detail_provider} className={props.visibility_detail_provider ? "minus" : "plus"}></div>
                </h2>
            </div>
            {provider_data}            
        </div>
    )
};

export default APIResponse;





