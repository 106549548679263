/*
2021-07-05 SVEN Kommentarbereich für Versionierung, Kommentare, let provider_detail ungenutzt raus (Zeile 13)
2021-07-06 SVEN Zeile 23 if erweitert --> neue Version Skript Hannes liefert Verbindungsqualität großgeschrieben, deshalb beide Fälle abgefangen "gut" und "Gut", Verbindungsqualität in Text
2021-08-19  erklärtooltips mit react-tooltip, Komponente erzeugt --> Import in SngleFieldAPI & MultiField dort Erzeugung von Button für Erklärtooltip
2021-08-25  simpletooltip angepasst, erzeugt jetzt feld im MultiField anstatt tooltip, besser lesbar + verschiebt darunter liegendes feld, damit nichts verdeckt wird
            + Bereinigung Codeüberreste "divschieber"
*/

import React from 'react';
import './MultiField.css'
import SimpleTooltip from './SimpleTooltip'
import TutTooltip from './TutTooltip';

// im Multifield können mehrere Informationen angezeigt werden --> Unterschied Singlefield

const MultiField = (props) => {
    let api_provider_detail = props.value;
    const providerclass = [];
    let available_technology = {
        "gsm": "inactive",
        "umts": "inactive",
        "lte": "inactive"
    }
    const keys_provider_detail = Object.keys(api_provider_detail)
    const quali = []
    for (const key_provider_detail of keys_provider_detail) {
        // Verarbeitung der Verbindungsqualität des einzelnen Providers 
        // Verbindungsqualität wird in farbigem Text angezeigt
        
        if (key_provider_detail === 'quality') {
            if (api_provider_detail[key_provider_detail] === 'Keine Werte vorhanden') {
                continue
            }
            else if (api_provider_detail[key_provider_detail] === 'gut' || api_provider_detail[key_provider_detail] === 'Gut') {
                providerclass.push("green")
                quali.push(<h4 className={providerclass.join(" ")}>Mobilfunkverbindung gut</h4>);
            }
            else if (api_provider_detail[key_provider_detail] === 'ausreichend' || api_provider_detail[key_provider_detail] === 'Ausreichend') {
                providerclass.push("orange")
                quali.push(<h4 className={providerclass.join(" ")}>Mobilfunkverbindung ausreichend</h4>);
            }
            else if (api_provider_detail[key_provider_detail] === 'schlecht' || api_provider_detail[key_provider_detail] === 'Schlecht') {
                providerclass.push("red")
                quali.push(<h4 className={providerclass.join(" ")}>Mobilfunkverbindung schlecht</h4>);
            }
            else{
                providerclass.push("grey")
                quali.push(<h4 className={providerclass.join(" ")}>Keine Bewertung des Providers möglich</h4>);
            }            
        }
        //Welche Technologien des jeweiligen Providers sind verfügbar?
        else {
            let access_technology = api_provider_detail[key_provider_detail]
            if (key_provider_detail === "gsm") {
                if (access_technology.rssi !== '-' && access_technology.rssi !== 'Keine Werte vorhanden') { 
                    available_technology[key_provider_detail] = 'active'}
            }
            if (key_provider_detail=== "umts") {
                if (access_technology.rscp !== '-' && access_technology.rscp !== 'Keine Werte vorhanden') { 
                    available_technology[key_provider_detail] = 'active'}
            }
            if (key_provider_detail === "lte") {
                if (access_technology.rsrq !== '-' && access_technology.rsrq !== 'Keine Werte vorhanden') { 
                    available_technology[key_provider_detail] = 'active'}
            }
        }
    } 
    
    const text_detailprovider = "Bewertung der Empfangsqualität des jeweiligen Providers <br> Klick auf die Empfangstechnologien öffnet Detailwerte"
    const text_gsm = "Grenzwerte GSM:<br>Gut:<br> RSSI bis -85 dBm <br>Ausreichend:<br> RSSI von -86 dBm bis -95 dBm <br>Schlecht:<br> RSSI ab -96 dBm"
    const text_umts = "Grenzwerte UMTS:<br>Gut:<br> RSCP bis -85 dBm, EC/IO bis -10 dB <br>Ausreichend:<br> RSCP von -86 dBm bis -95 dBm, EC/IO von -11 dB bis -15 dB <br> Schlecht:<br> RSCP ab -96 dBm, EC/IO ab -16 dB"
    const text_lte = "Grenzwerte LTE:<br>Gut:<br> RSRP bis -95 dBm, RSRQ bis -10 dB <br> Ausreichend:<br> RSRP von -96 dBm bis -105 dBm, RSRQ von -11 dB bis -15 dB <br> Schlecht:<br> RSRP ab -106 dBm, RSRQ ab -16 dB"

    let tooltip_gsm = []
    let tooltip_umts = []
    let tooltip_lte = []
    
    tooltip_gsm.push(<a data-tip={text_gsm} data-event="click" className="tooltiptrigger_provider">?</a>)
    tooltip_umts.push(<a data-tip={text_umts} data-event="click" className="tooltiptrigger_provider">?</a>)
    tooltip_lte.push(<a data-tip={text_lte} data-event="click" className="tooltiptrigger_provider">?</a>)
    
    let array_to_show_tips = []

    // fängt alle möglichen Header ab, so werden alle Provider inkl. "Unbekannt" erschlagen
    let tooltip_Provider_egal = []
    if (props.header !== "") {
        tooltip_Provider_egal.push(<a data-tip={text_detailprovider} data-event="click" className="tooltiptrigger">?</a>)
        array_to_show_tips.push(tooltip_Provider_egal)
    }

    // Rückgabe an app.js, Werte des Providers je Technologie werden per SimpleTooltip angezeigt
    return (
        <div className="MultiField">
            <TutTooltip />
            <h3>{props.header}</h3>
            {array_to_show_tips} 
            {quali}
            <table id="multifieldtooltiptable">
            <td class="multifieldtooltiptable_td"><SimpleTooltip name="GSM" class={"provider " + available_technology.gsm} state={available_technology.gsm} signal_information={api_provider_detail.gsm} tooltip={tooltip_gsm}/> </td>
            <td class="multifieldtooltiptable_td"><SimpleTooltip name="UMTS" class={"provider " + available_technology.umts} state={available_technology.umts} signal_information={api_provider_detail.umts} tooltip={tooltip_umts}/></td>
            <td class="multifieldtooltiptable_td"><SimpleTooltip name="LTE" class={"provider " + available_technology.lte} state={available_technology.lte} signal_information={api_provider_detail.lte} tooltip={tooltip_lte}/> </td>
            </table>
        </div>
    )
};

export default MultiField;