/*
2021-07-05 SVEN Kommentarbereich für Versionierung
2021-07-06 SVEN mapper erweitert um neue Punkte
2021-08-12  Kategorie 28d Wert raus, Reconnect Quality rein, Online Status in aktueller Verbindungsstatus geändert, Verbindungsqualität in Verbindungsqualität zum Backend geändert
2021-08-19  erklärtooltips mit react-tooltip, Komponente erzeugt --> Import in SngleFieldAPI & MultiField dort Erzeugung von Button für Erklärtooltip
*/

import React from 'react';
import './SingleFieldAPI.css'
import TutTooltip from './TutTooltip';

// SingleFieldAPI gibt Box mit Überschrift und einem Wert aus, genutzt bei Status, Providerinformationen und Fehlermeldungen
const SingleFieldAPI = (props) => {

    // mapping um schöne Überschriften zu erzeugen
    const mapper = {
        "event_count": "Anzahl Events",
        "online_status": "Verbindungsstatus zum Backend",
        "mounting_place_rating": "Mobilfunk am Montageplatz",
        "firmware_status": "Firmware Status",
        "on_off_value": "Verbindungsqualitätsquote",
        "conn_quality": "Verbindungsqualität zum Backend",
        "last_connect": "Letzte Verbindung",
        "act_operator": "Provider",
        "act_access_technology": "Technologie",
        "firmware_name": "Firmware",
        "reconnect_count": "Reconnects letzte 7 Tage",
        "reconnect_quality": "Reconnect Quality ALT",
        "Firmware": "Firmware",
        "Reconnects": "Qualität Verbindungsversuche",
        "online_since": "Letzter Verbindungszeitpunkt",
        "offline_since": "Offline seit",
        "mobile_quality_rating": "Bewertung der Mobilfunkverbindung",
        "mobile_quality_rating_reason": "Begründung der Mobilfunkbewertung",
        "instruction": "Handlungsempfehlung",
        "test": "Testtitel",

    }

    const text_firmware = "Auf dem Gateway installierte Firmwareversion"
    const text_reconnect = "Stabilität und Anzahl der Verbindungsversuche innerhalb der letzten 7 Tage"
    const text_mobile_quality = "Bewertung der Mobilfunkverbindung am Montagestandort"
    const text_mobile_quality_reason = "Detaillierte Begründung zur Bewertung des Mobilfunkempfangs am Montagestandort"
    const text_connection_status = "Gateway aktuell verbunden - Ja oder Nein"
    const text_connection_backend = "Bewertung der Qualität der Verbindung zum Backend <br> Gut ab einer Verbindungsquote >=90% <br> Instabil <90% und >=30% <br> Schlecht ab <30% <br> Noch keine Bewertung möglich, wenn das Gateway noch nicht lange genug in Betrieb ist um eine Aussage zu treffen"
    const text_7d = "Prozentualer Anteil der aktiven Verbindung innerhalb der letzten 28 Tage <br> Gut ab einer Verbindungsquote >=90% <br> Instabil <90% und >=30% <br> Schlecht ab <30%"
    const text_last_connection = "Zeitstempel der letzten Verbindung"
    const text_provider = "Aktuell zur Mobilfunkverbindung im Einsatz befindlicher Provider <br> Alte Firmwareversionen (GW V2: <1.1.5, V1: <1.40.10) senden keine Informationen zum Provider"
    const text_provider_technology = "Aktuell zur Mobilfunkverbindung im Einsatz befindliche Technologie"
    const text_instruction = "Handlungsempfehlung bei Begehung"


    let array_to_show_tips = []
    
    let tooltip_firmware = []
    if (props.header === "Firmware") {
        tooltip_firmware.push(<a data-tip={text_firmware} data-event="click" className="tooltiptrigger">?</a>)
        array_to_show_tips.push(tooltip_firmware)
    }
    let tooltip_reconnect = []
    if (props.header === "Reconnects") {
        tooltip_reconnect.push(<a data-tip={text_reconnect} data-event="click" className="tooltiptrigger">?</a>)
        array_to_show_tips.push(tooltip_reconnect)
    }
    let tooltip_mobile_quality = []
    if (props.header === "mobile_quality_rating") {
        tooltip_mobile_quality.push(<a data-tip={text_mobile_quality} data-event="click" className="tooltiptrigger">?</a>)
        array_to_show_tips.push(tooltip_mobile_quality)
    }
    let tooltip_mobile_quality_reason = []
    if (props.header === "mobile_quality_rating_reason") {
        tooltip_mobile_quality_reason.push(<a data-tip={text_mobile_quality_reason} data-event="click" className="tooltiptrigger">?</a>)
        array_to_show_tips.push(tooltip_mobile_quality_reason)
    }
    let tooltip_connection_status = []
    if (props.header === "online_status") {
        tooltip_connection_status.push(<a data-tip={text_connection_status} data-event="click" className="tooltiptrigger">?</a>)
        array_to_show_tips.push(tooltip_connection_status)
    }
    let tooltip_connection_backend = []
    if (props.header === "conn_quality") {
        tooltip_connection_backend.push(<a data-tip={text_connection_backend} data-event="click" className="tooltiptrigger">?</a>)
        array_to_show_tips.push(tooltip_connection_backend)
    }

    let tooltip_instruction = []
    if (props.header === "instruction") {
        tooltip_instruction.push(<a data-tip={text_instruction} data-event="click" className="tooltiptrigger">?</a>)
        array_to_show_tips.push(tooltip_instruction)
    }



    let tooltip_7d = []
    if (props.header === "on_off_value") {
        tooltip_7d.push(<a data-tip={text_7d} data-event="click" className="tooltiptrigger">?</a>)
        array_to_show_tips.push(tooltip_7d)
    }
    let tooltip_last_connection = []
    if (props.header === "online_since" || props.header === "offline_since") {
        tooltip_last_connection.push(<a data-tip={text_last_connection} data-event="click" className="tooltiptrigger">?</a>)
        array_to_show_tips.push(tooltip_last_connection)
    }
    let tooltip_provider = []
    if (props.header === "act_operator") {
        tooltip_provider.push(<a data-tip={text_provider} data-event="click" className="tooltiptrigger">?</a>)
        array_to_show_tips.push(tooltip_provider)
    }
    let tooltip_provider_technology = []
    if (props.header === "act_access_technology") {
        tooltip_provider_technology.push(<a data-tip={text_provider_technology} data-event="click" className="tooltiptrigger">?</a>)
        array_to_show_tips.push(tooltip_provider_technology)
    }
    
    return (
        <div className="InfoField">
            <TutTooltip />
            <h3>{mapper[props.header]}</h3>   
            {array_to_show_tips}                   
            <p className={props.klasse}>{props.value}</p>
        </div>
    )
};

export default SingleFieldAPI;